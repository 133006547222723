import { createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" 最外层需要一个透明遮罩，监听点击事件 "),
    _createElementVNode("div", {
      class: "mo-mask",
      style: { 'z-index': 9999 },
      onMouseup: _withModifiers($setup.close, ["stop"])
    }, [
      _createVNode(_Transition, { name: "el-fade-in-linear" }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: "pop-share",
            style: _normalizeStyle($setup.style)
          }, [
            _createElementVNode("div", {
              class: "pop-share__item",
              onMouseup: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('copy')), ["stop"]))
            }, "复制", 32 /* NEED_HYDRATION */),
            _createElementVNode("div", {
              class: "pop-share__item",
              onMouseup: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.$emit('search')), ["stop"]))
            }, "搜索", 32 /* NEED_HYDRATION */)
          ], 4 /* STYLE */)
        ]),
        _: 1 /* STABLE */
      })
    ], 32 /* NEED_HYDRATION */)
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}